import React from 'react'
import Links from './Links'

const Navbar = () =>{
    const links = <Links/>;
    return(
        <div className="navbar-fixed">
            <nav className="navbar nav-wrapper">
                <div className="container">
                    <a href='#home' className="brand-logo name"><h5>Vinicius Lage</h5></a>
                    { links }
                </div>
            </nav>
        </div>
    )
}

export default Navbar