import React from 'react'

const PortfolioModal = (props) => {
    
    return(
        <div>
            <div id={props.modal} className="modal">
                <div className="modal-content blue-grey darken-4">
                    <div className="row card blue-grey lighten-5">
                        <div className="portfolio-modal-title-panel col l12 m12 s12">
                            <h4 className="portfolio-modal-title center-align blue-grey-text text-darken-4">{props.title}</h4>
                        </div>
                        <div className="portfolio-modal-title-panel col l12 s12">
                            <div className="video-container card">
                                <iframe id={props.id} title="Portfolio Video" width="853" height="480" src={props.video} frameborder="0" allowFullScreen></iframe>
                            </div>
                        </div>
                        <div className="modal-gallery col l4 m6">
                            <img src={require(`../../img/${props.name}image1.PNG`)} alt="" className="responsive-img card z-depth-2 materialboxed" />
                        </div>
                        <div className="modal-gallery col l4 m6">
                            <img src={require(`../../img/${props.name}image2.PNG`)} alt="" className="responsive-img card z-depth-2 materialboxed" />
                        </div>
                        <div className="modal-gallery col l4 m6">
                            <img src={require(`../../img/${props.name}image3.PNG`)} alt="" className="responsive-img card z-depth-2 materialboxed" />
                        </div>
                        <div className="modal-gallery col l4 m6">
                            <img src={require(`../../img/${props.name}image4.PNG`)} alt="" className="responsive-img card z-depth-2 materialboxed" />
                        </div>
                        <div className="modal-gallery col l4 m6">
                            <img src={require(`../../img/${props.name}image5.PNG`)} alt="" className="responsive-img card z-depth-2 materialboxed" />
                        </div>
                        <div className="modal-gallery col l4 m6">
                            <img src={require(`../../img/${props.name}image6.PNG`)} alt="" className="responsive-img card z-depth-2 materialboxed" />
                        </div>
                        <div className="modal-gallery col l4 m6">
                        {
                            //<img src={require(`../../img/${props.path}image7.PNG`)} alt="" className="responsive-img card z-depth-2 materialboxed" />

                        }
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PortfolioModal