import React, { Component } from 'react'
import firstGame from '../../img/cyberhunter/image1.PNG'
import secondGame from '../../img/skate/image1.PNG'
import thirdGame from '../../img/anthropou/image6.PNG'
import fourthGame from '../../img/arco/image3.PNG'
import fifthGame from '../../img/alley/image1.PNG'

class Portfolio extends Component{
    render(){
        return(
            <div className="section-header blue-grey lighten-5">
                <section className="section scrollspy" id="portfolio">
                    <h4 className="section-title light-blue-text text-accent-4 center-align">PROJETOS ACADÊMICOS</h4>
                    <div className="section-container container row">
                        <div className="col s12 m12 l6 ">
                            <a className="modal-trigger" href="#modal1">
                                <div className="card portfolio-card hoverable">
                                    <div className="card-image">
                                        <img src={firstGame} alt="" className="portfolio-image" />
                                        <span className="card-title purple-text text-lighten-3 black valign-wrapper" style={{fontSize: '20px', height: '1%', marginBottom: '10px', padding: '15px'}}>CYBER HUNTER</span>
                                    </div>
                                    <div className="card-content">
                                        <p className="grey-text text-darken-3">Cyber Hunter é um jogo mobile de ação, que envolve uma trama num futuro distópico. 
                                            O jogo possui combates com armas e corpo-a-corpo no estilo hack'n slash, além de skills, locais secretos e diversos 
                                            puzzles com tema hacker.
                                        </p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col s12 m12 l6">
                            <a className="modal-trigger" href="#modal2">
                                <div className="card portfolio-card hoverable">
                                    <div className="card-image">
                                        <img src={secondGame} alt="" className="portfolio-image" />
                                        <span className="card-title amber-text text-lighten-1 black valign-wrapper" style={{fontSize: '20px', height: '1%', marginBottom: '10px', padding: '15px'}}>SKATE STREET</span>
                                    </div>
                                    <div className="card-content">
                                        <span className="card-title blue-text text-lighten-1"></span>
                                        <p className="grey-text text-darken-3">Skate Street é um jogo mobile no estilo 'Runner', onde o jogador deve coletar moedas,
                                            desviar de obstáculos e fazer manobras para ganhar mais pontos.</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col s12 m12 l6">
                            <a className="modal-trigger" href="#modal3">
                                <div className="card portfolio-card hoverable">
                                    <div className="card-image">
                                        <img src={thirdGame} alt="" className="portfolio-image" />
                                        <span className="card-title red-text text-accent-2 black valign-wrapper" style={{fontSize: '20px', height: '1%', marginBottom: '10px', padding: '15px'}}>ANTHROPOU</span>
                                    </div>
                                    <div className="card-content">
                                        <span className="card-title blue-text text-lighten-1"></span>
                                        <p className="grey-text text-darken-3">Anthropou - Change It se baseia na teoria do evolucionismo e o jogador deve resolver desafios
                                            em cada época da evolução humana. O jogo foi desenvolvido durante a Epic Game Jam - Belo Horizonte, em 2017, ficando com
                                            a terceira colocação.</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col s12 m12 l6">
                            <a className="modal-trigger" href="#modal4">
                                <div className="card portfolio-card hoverable">
                                    <div className="card-image">
                                        <img src={fourthGame} alt="" className="portfolio-image"/>
                                        <span className="card-title cyan-text text-lighten-1 black valign-wrapper" style={{fontSize: '20px', height: '1%', marginBottom: '10px', padding: '15px'}}>ARCO'S LEGEND</span>
                                    </div>
                                    <div className="card-content">
                                        
                                        <p className="grey-text text-darken-3 ">Arco's Legend é um jogo de plataforma em 2D. O jogador deve enfrentar animais amaldiçoados
                                            e coletar gemas do poder para restabelecer a paz na natureza.</p>
                                        
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="section-container container row" >
                        <h4 className="section-title light-blue-text text-accent-4 center-align" >JOGOS LANÇADOS</h4>
                        <div className="col s12 m12 l6" style={{'margin-top': '40px'}}>
                            <a className="modal-trigger" style={{display: "table-cell"}} rel="noopener noreferrer" target="_blank" href="https://play.google.com/store/apps/details?id=com.PunchBag.AlleyOopDunk&hl=pt">
                                <div className="card portfolio-card hoverable">
                                    <div className="card-image">
                                        <img src={fifthGame} alt="" className="portfolio-image" />
                                        <span className="card-title orange-text text-lighten-1 black valign-wrapper" style={{fontSize: '20px', height: '1%', marginBottom: '10px', padding: '15px'}}>ALLEY OOP DUNK</span>
                                    </div>
                                    <div className="card-content">
                                        <span className="card-title blue-text text-lighten-1"></span>
                                        <p className="grey-text text-darken-3">Alley Oop Dunk é um jogo casual de basquete onde o jogador deve fazer cestas, realizar combos, completar missões, desviar
                                            de obstáculos, liberar fases e disputar sua pontuação no ranking.
                                            
                                        </p>
                                        <p className="grey-text text-darken-3" style={{'padding-top': '20px'}}>
                                            Clique para acessar a página na Google Play.
                                        </p>
                                        
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default Portfolio