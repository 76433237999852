import React from 'react'

import image1 from '../../img/alley/image1.PNG'
import image2 from '../../img/alley/image2.PNG'
import image3 from '../../img/alley/image3.PNG'
import image4 from '../../img/alley/image4.PNG'
import image5 from '../../img/alley/image5.PNG'

const PortfolioModal5 = () => {
    return(
        <div>
            <div id="modal5" className="modal">
                <div className="modal-content blue-grey darken-4">
                    <div className="row card blue-grey lighten-5">
                        <div className="portfolio-modal-title-panel col l12 m12 s12">
                            <h4 className="portfolio-modal-title center-align blue-grey-text text-darken-4">ALLEY OOP DUNK</h4>
                        </div>
                        <div className="portfolio-modal-title-panel col l12">
                            <img src={image1} alt="" className="responsive-img card z-depth-2 materialboxed" />                            
                        </div>
                        <div className="modal-gallery col l4 m6">
                            <img src={image2} alt="" className="responsive-img card z-depth-2 materialboxed" />
                        </div>
                        <div className="modal-gallery col l4 m6">
                            <img src={image3} alt="" className="responsive-img card z-depth-2 materialboxed" />
                        </div>
                        <div className="modal-gallery col l4 m6">
                            <img src={image4} alt="" className="responsive-img card z-depth-2 materialboxed" />
                        </div>
                        <div className="modal-gallery col l4 m6">
                            <img src={image5} alt="" className="responsive-img card z-depth-2 materialboxed" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PortfolioModal5