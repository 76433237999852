import React from 'react'

const Links = () => {
    return(
        <div className="container">
            <a href="#home" className="sidenav-trigger" data-target="mobile-menu">
                <i className="material-icons">menu</i>
            </a>
            <ul className="right hide-on-med-and-down">
                <li><a href='#home'>HOME</a></li>
                <li><a href="#about">SOBRE</a></li>
                <li><a href="#services">SERVIÇOS</a></li>
                <li><a href="#portfolio">PROJETOS</a></li>
                <li><a href="#contact">CONTATO</a></li>
            </ul>
            <ul className="sidenav blue-grey darken-3" id="mobile-menu">
                <li><a className="sidenav-close white-text" href='#home'>HOME</a></li>
                <li><a className="sidenav-close white-text" href="#about">SOBRE</a></li>
                <li><a className="sidenav-close white-text" href="#services">SERVIÇOS</a></li>
                <li><a className="sidenav-close white-text" href="#portfolio">PROJETOS</a></li>
                <li><a className="sidenav-close white-text" href="#contact">CONTATO</a></li>
            </ul>
        </div>
    )
}

export default Links