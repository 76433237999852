import React, { Component } from 'react'

class About extends Component{
    render(){
        return(
            <div className="section-header blue-grey lighten-5">
                <section className="section scrollspy" id="about">
                    <h4 className="section-title light-blue-text text-accent-4 center-align">SOBRE</h4>
                    <div className="myProfile container">
                        <div className="row section-container">
                        <div className="about-text-header col l7 m8 s12 offset-m2">
                            <div className="about-text-title blue-grey-text text-darken-4">Desenvolvimento Web | Desenvolvimento de jogos | Unity Developer</div>
                            <p className="about-text">
                                Apaixonado por tecnologia, jogos e programação. Comecei a programar e desenvolver 
                                meus primeiros códigos em 2009. A partir de 2016 comecei a me aventurar no desenvolvimento
                                de jogos.
                            </p>
                            <p className="about-text">
                                Desde então, tive contato com diversas engines, como: Unreal Engine, Game Maker, 
                                Unity Software, Amazon Lumberyard. Além de ferramentas artísticas: Autodesk Maya, Adobe Photoshop, entre outros.
                            </p>
                            <p className="about-text">
                                Atualmente trabalho com PHP, Javascript, e HTML. Nas horas livres trabalho com desenvolvimento de jogos casuais para mobile utilizando a Unity Software e sou um entusiasta
                                das linguagens React JS e Ionic.
                            </p>
                        </div>
                        <div className="profile-img-header col l3 m6 s8 offset-s2 offset-m3 offset-l2">
                            <div className="profile-img img-responsive img-circle" ></div>
                        </div>
                        </div>    
                    </div>
                </section>
            </div>
        )
    }
}



export default About