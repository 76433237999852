import React, { Component } from 'react';
import Navbar from './components/layout/Navbar'
import Home from './components/home/Home'
import About from './components/about/About'
import Footer from './components/layout/Footer'
//import Parallax from './components/Parallax';
//import basketball from './img/vinicius_enterrada2.jpg'
import Portfolio from './components/portfolio/Portfolio'
import PortfolioModal from './components/modals/PortfolioModal'
import PortfolioModal5 from './components/modals/PortfolioModal5'
import Services from './components/services/Services'
import Contact from './components/contact/Contact';
import Division from './components/Division';

class App extends Component {
  render() {
    return (
        <div className="App">
          <Navbar />
          <Home />
          <About />
          <Division />
          {//<Parallax image={basketball}/>
          }
          <Services />
          {//<Parallax image={basketball}/>
          }
          <Division />
          <Portfolio />
          <PortfolioModal modal={'modal1'} name={'cyberhunter/'} title={'Cyber Hunter'} video={'https://www.youtube.com/embed/iUHltWn-ZvM'} id={'video-frame1'}/>
          <PortfolioModal modal={'modal2'} name={'skate/'} title={'Skate Street'} video={'https://www.youtube.com/embed/jW9r_khTmxc'} id={'video-frame2'}/>
          <PortfolioModal modal={'modal3'} name={'anthropou/'} title={'Anthropou - Change It'} video={'https://www.youtube.com/embed/03BU4W4e7h0'} id={'video-frame3'}/>
          <PortfolioModal modal={'modal4'} name={'arco/'} title={'Arco\'s Legend'} video={'https://www.youtube.com/embed/ZF3XQ3gihP8'} id={'video-frame4'}/>
          <PortfolioModal5 modal={'modal5'} name={'alley/'} title={'Alley Oop Dunk'} />
          {//<Parallax image={basketball}/>
          <Contact />
          }
          <Footer/>
        </div>
    );
  }
}

export default App;
