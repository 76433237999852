import React, {Component} from 'react'

class Contact extends Component {
    state = {
        name: '',
        email: '',
        message: '',
      }

    render(){
        return(
            <div className="section-header blue-grey lighten-5">
            <section className="section scrollspy" id="contact">
                {/*
                    <h4 className="section-title light-blue-text text-accent-4 center-align">CONTATO</h4>
                        <div className="section-container container">
                        <form className="container" onSubmit={this.handleSubmit}>
                            <div className="input-field">
                                <label htmlFor="name">Nome</label>
                                <input type="text" id='name' onChange={this.handleChange} />
                            </div>
                            <div className="input-field">
                                <label htmlFor="email">Email</label>
                                <input type="email" id='email' className='validate' />
                                <div className="helper-text" data-error="Email Incorreto"></div>
                            </div>
                            <div className="input-field" style={{marginTop:'-18px'}}>
                                <textarea id="message" className="materialize-textarea" data-length="300"></textarea>
                                <label htmlFor="message">Mensagem</label>
                            </div>
                            <div className="submit input-field">
                                <button className="btn light-blue accent-4 z-depth-0">Enviar</button>
                            </div>
                            </form>
                        </div>*/
                }
                
            </section>
            </div>
        )
    }
    
}

export default Contact