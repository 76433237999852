import React, { Component } from 'react'
import game from '../../img/game1.jpg'
import code from '../../img/code.png'

class Home extends Component{
    render(){
        return(
            <div className="home-header">
                <section className="section home scrollspy parallax-container" id="home">
                    <div className="container grey-text text-lighten-3 ">
                        <h1 className="jobtitle blue-grey-text text-lighten-5 text center-align" style={{textShadow: '2px 2px #263238'}}>Vinicius Lage</h1>
                        {//<h4 className="grey-text text-lighten-3 center-align" style={{textShadow: '2px 2px #263238'}}>Web Developer | Game Developer</h4> 
                        }
                        <div class="animate">
                            <div class="one center-align">
                                <span className="gamedev">G</span><span className="gamedev">A</span><span className="gamedev">M</span><span className="gamedev">E</span> &nbsp;
                                <span className="gamedev">D</span><span className="gamedev">E</span><span className="gamedev">V</span><span className="gamedev">E</span><span className="gamedev">L</span><span className="gamedev">O</span><span className="gamedev">P</span><span className="gamedev">E</span><span className="gamedev">R</span>
                            </div>
                            <div class="two center-align">
                                <span className="webdev">W</span><span className="webdev">E</span><span className="webdev">B</span> &nbsp;
                                <span className="webdev">D</span><span className="webdev">E</span><span className="webdev">V</span><span className="webdev">E</span><span className="webdev">L</span><span className="webdev">O</span><span className="webdev">P</span><span className="webdev">E</span><span className="webdev">R</span>
                            </div>
                        </div>
                    </div>
                    <div className="parallax">
                    <div id="cf">
                        <img className="bottom" alt="" src={game} />
                    </div>
                    </div>
                    <div className="parallax">
                        <div id="cf">
                            <img className="top" alt="" src={code} />
                        </div>
                    </div>
                </section>
                
            </div>
            
        )
    }
}

export default Home