import React from 'react';

const Division = (props) =>
{
    return(
        <div>
            <div className="blue-grey lighten-5">
                <div className="division blue-grey darken-5 container">

                </div>
            </div>
        </div>
    )
}

export default Division