import React from 'react'

const Services = () => {
    return(
        <div className="section-header blue-grey lighten-5">
        <section className="section scrollspy" id="services">
            <h4 className="section-title light-blue-text text-accent-4 center-align">SERVIÇOS</h4>
            <div className="section-container container row">
                <div className="col l4 m6 s6 center-align">
                    <i className="services-icons light-blue-text text-accent-4 fab fa-react"></i>
                    <div className="services-text">REACT JS</div>
                </div>
                <div className="col l4 m6 s6 center-align">
                    <i className="services-icons light-blue-text text-accent-4 fab fa-js"></i>
                    <div className="services-text">JAVASCRIPT</div>
                </div>
                <div className="col l4 m6 s6 center-align">
                    <i className="services-icons light-blue-text text-accent-4 fab fa-html5"></i>
                    <div className="services-text">HTML | CSS 3</div>
                </div>
                <div className="col l4 m6 s6 center-align">
                    <i className="services-icons light-blue-text text-accent-4 fas fa-gamepad"></i>
                    <div className="services-text">UNITY SOFTWARE</div>
                </div>
                <div className="col l4 m6 s6 center-align">
                    <i className="services-icons light-blue-text text-accent-4 fab fa-php"></i>
                    <div className="services-text">PHP</div>
                </div>
                <div className="col l4 m6 s6 center-align">
                    <i className="services-icons light-blue-text text-accent-4 fab fa-github"></i>
                    <div className="services-text">GITHUB</div>
                </div>
            </div>
        </section>
        </div>
    )
}

export default Services