import React from 'react'

const Footer = () =>{
    return(
        <div className="footer blue-grey darken-4">
          <footer className="page-footer blue-grey darken-4">
          <div className="container">
              <div className="footer-row row">
                <div className="col l6 s12">
                  <h5 className="white-text">Contato</h5>
                  <div className="row">
                    <div className="contact-footer-header col l1 m2 s2">
                      <i className="contact-footer fas fa-mobile-alt"></i>
                    </div>
                    <div className="contact-footer-text col l10 m10 s10">
                      <p>(31) 99497 8734</p>
                    </div>
                  </div>
                  <div className="contact-row row">
                    <div className="contact-footer-header col l1 m2 s2">
                      <i className="contact-footer fas fa-envelope"></i>
                    </div>
                    <div className="contact-footer-text col l10 m10 s10">
                      <p>vinicius_clage@yahoo.com.br</p>
                    </div>
                  </div>
                </div>
                <div className="col l4 offset-l2 s12">
                  <h5 className="">Media</h5>
                  <div className="row">
                    <div className="footer-media col l2">
                      <a href="https://www.facebook.com/vinicius.lage.54" target="_blank" rel="noopener noreferrer" className=""><i className="social fab fa-facebook"></i></a>
                    </div>
                    <div className="footer-media col l2">
                      <a href="https://www.linkedin.com/in/vinicius-lage/" target="_blank" rel="noopener noreferrer" className=""><i className="social fab fa-linkedin"></i></a>
                    </div>
                    <div className="footer-media col l2">
                      <a href="https://github.com/Vinlage" target="_blank" rel="noopener noreferrer" className=""><i className="social fab fa-git-square"></i></a>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
            <div className="footer-copyright">
                
            </div>
          </footer>
            
        </div>
    )
}

export default Footer